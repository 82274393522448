import React from 'react'

import Helmet from "react-helmet"

const Meta = (props) => (
    <Helmet
        htmlAttributes={{
            lang: 'ja',
        }}
        title={ (props.title ? props.title + ' | ' : '') + "しくみを創る企業 | アクロスソリューションズ"  }
        meta={[
            {
                name: 'description',
                content: props.description || 'アクロスソリューションズ'
            }
        ]}   
    >
        <script type="text/javascript" src={process.env.SITE_URL + `/api.js`}></script>
    </Helmet>
)

export default Meta;



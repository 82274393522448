import React from 'react';

import { useLocation } from "@reach/router"

import * as styles from '../styles/layout.module.scss';

const Contents = ({children, position, pageContext, ...props}) =>{

    const positions = position;
    // console.log(pageContext);

    let location = useLocation()
    let chkLocation = location.pathname



    return(
        <>
            <div className={`l-content ${styles.wrapper} ${(positions !== 'home')? 'is-sub':''} ${(chkLocation === '/thankyou/')? 'is-thankyou':''}`}>
                {children}
            </div>
        </>
    )    
}

export default Contents;


